/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    &:hover {
      border-color: var(--#{$prefix}border-color);
    }
  }
  .css-t3ipsp-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    box-shadow: none;
    &:hover {
      border-color: var(--#{$prefix}border-color) !important;
    }
  }
  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}gray-200);
    .css-10wo9uf-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-200);
      &:active {
        background-color: var(--#{$prefix}gray-200);
      }
    }
    .css-d7l1ni-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-100);
    }
  }
  .css-1p3m7a8-multiValue {
    background-color: var(--#{$variable-prefix}light);
  }
  .css-wsp0cs-MultiValueGeneric {
    color: $heading-color;
  }
  .css-12jo7m5 {
    color: $heading-color;
  }
  .css-6j8wv5-Input,
  .css-qc6sy-singleValue {
    color: $heading-color;
  }

  .css-1dimb5e-singleValue {
    color: var(--vz-body-color);
  }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 22px;
  line-height: 2;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

// Data Table

.kVrXuC,
.dwPlXY,
.gKbhqU,
.iSAVrt {
  background-color: $table-bg !important;
  color: $table-color !important;
}

.fyrdjl {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
}

.fyrdjl:disabled {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
}

.iSAVrt {
  background-color: var(--#{$prefix}card-bg-custom) !important;
  border-bottom-color: $table-border-color !important;
}

.dwPlXY:not(:last-of-type) {
  border-bottom-color: $table-border-color !important;
}

// .react-data-table-component table.dataTable thead th {
//   border: 1px solid black;
// }
.profile-header {
  position: relative;
}

.cover-image {
  position: relative;
  background-color: #f4f4f4;
}

.cover-image .btn-add-cover {
  position: absolute;
  top: 10px;
  left: 10px;
}

.cover-image .btn-share-profile {
  position: absolute;
  top: 10px;
  right: 10px;
}

.profile-picture img {
  width: 150px;
  height: 150px;
}

.profile-images .image-upload-box {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.profile-images .image-upload-box .image-placeholder {
  background-color: #e9e9e9;
  padding: 50px;
  border-radius: 5px;
  position: relative;
}

.profile-images .image-upload-box .btn-upload {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

/* Ensure the cover image takes up the full width */
.profile-cover {
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  object-fit: cover;
  position: relative;
  z-index: 1;
}

/* Style the profile container to overlap with the cover image */
.profile-container {
  position: relative;
  margin-top: -150px; /* Adjust this value to control how much the profile overlaps with the cover */
  z-index: 2;
  padding: 20px;
}

/* Style the profile picture */
.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid white;
  object-fit: cover;
  position: relative;
  z-index: 3;
}

/* Optional: style the action buttons */
.profile-actions {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 4;
}

/* Adjust the profile info section */
.profile-info {
  margin-top: 10px;
  z-index: 4;
}

.pending-event {
  animation: glow 1.5s infinite alternate, shake 0.5s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(255, 193, 7, 0.5);
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 193, 7, 1);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(3px);
  }
}

.modal-content {
  overflow: visible !important; /* Allow the dropdown to show inside the modal */
}

.rs-anim-fade.rs-anim-in {
  z-index: 9999;
}

.day-hours {
  margin-bottom: 15px;
}

.slot {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.slot .timepicker {
  width: 170px;
}

.slot button {
  margin-left: 10px;
}


